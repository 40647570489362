import { render, staticRenderFns } from "./marketingOverview.vue?vue&type=template&id=457b11b6&scoped=true"
import script from "./marketingOverview.vue?vue&type=script&lang=js"
export * from "./marketingOverview.vue?vue&type=script&lang=js"
import style0 from "./marketingOverview.vue?vue&type=style&index=0&id=457b11b6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "457b11b6",
  null
  
)

export default component.exports
<template>
	<div class="marketingOverview" v-loading="Loading">
		<div class="splicer-time">
			<span>筛选时间：</span>
			<el-date-picker v-model="startDate" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
			 :picker-options="maxOptions" placeholder="开始时间" :clearable='false' @change='changeRangeTime'></el-date-picker>
			<span style="padding: 0 10px;">至</span>
			<el-date-picker v-model="endDate" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
			 :picker-options="minOptions" placeholder="结束时间" :clearable='false' @change='changeRangeTime'></el-date-picker>
			<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.splicerTime"
			 placement="top-start">
				<i class="el-icon-warning icon"></i>
			</el-tooltip>
			<p class="small-show">目前统计的营销只包含优惠券、限时折扣以及满减送</p>
		</div>

		<!-- 营销概况 -->
		<div class="survey-box">
			<p class="titles">营销概况</p>
			<!-- 营销分析 -->
			<div class="marketAnlestics" style="margin-bottom: 10px;">
				<p>营销分析</p>
				<el-row class="line">
					<el-col :span="12">
						<el-row class="line">
							<el-col :span="5">
								<p>有效活动数</p>
								<p>{{basicmodelData.EffectiveActivityCount}}</p>
							</el-col>
							<el-col :span="19">
								<div class="show-middles">
									<span class="green-box" :style="{width:EffectiveActivityDiscontCountWidth+'%'}"></span>
									<span class="yellow-box" :style="{width:EffectiveActivityMarkdownGiftCountWidth+'%'}"></span>
									<span class="red-box" :style="{width:EffectiveCouponCountWidth+'%'}"></span>
								</div>

								<div class="color-show">
									<span class="limite-time">
										<span class="green-circle"></span>限时折扣
									</span>
									<span class="limite-time">
										<span class="yellow-circle"></span>满减送
									</span>
									<span class="limite-time">
										<span class="red-circle"></span>优惠券
									</span>
								</div>
							</el-col>
						</el-row>
					</el-col>
					<el-col :span="12">
						<el-row class="line">
							<el-col :span="8">
								<p class="title-name">
									营销支付金额(元)
									<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ActivityPayMoney"
									 placement="top-start">
										<i class="el-icon-warning icon"></i>
									</el-tooltip>
								</p>
								<p class="title-money">{{basicmodelData.ActivityPayMoney}}</p>
							</el-col>
							<el-col :span="8">
								<p class="title-name">
									营销支付金额占比
									<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ActivityPayMoneyRate"
									 placement="top-start">
										<i class="el-icon-warning icon"></i>
									</el-tooltip>
								</p>
								<p class="title-money">{{basicmodelData.ActivityPayMoneyRate + '%'}}</p>
							</el-col>
							<el-col :span="8">
								<p class="title-name">
									ROI
									<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.Roi"
									 placement="top-start">
										<i class="el-icon-warning icon"></i>
									</el-tooltip>
								</p>
								<p class="title-money">{{basicmodelData.Roi}}</p>
							</el-col>
						</el-row>
					</el-col>

				</el-row>
			</div>

			<!-- 支付金额 -->
			<div class="marketAnlestics">
				<p>支付金额</p>
				<div class="echart-box bar">
					<echart-bar :echartBarData='vipFansBarData' :legendData='vipFansLegend'></echart-bar>
				</div>
			</div>
		</div>

		<!-- 效果分析 -->
		<div class="survey-box">
			<p class="titles">
				效果分析
			</p>

			<div class="marketAnlestics" style="margin-bottom: 10px;">
				<p>投入产出</p>
				<el-row class="line">
					<el-col :span="8">
						<p class="title-name">
							ROI
							<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.effectRoi"
							 placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</p>
						<p class="title-money">{{basicmodelData.Roi}}</p>
					</el-col>
					<el-col :span="8">
						<p class="title-name">
							营销支付金额(元)
							<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.effectActivityPayMoney"
							 placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</p>
						<p class="title-money">{{basicmodelData.ActivityPayMoney}}</p>
					</el-col>
					<el-col :span="8">
						<p class="title-name">
							营销优惠金额(元)
							<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.effectPreferentialMoney"
							 placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</p>
						<p class="title-money">{{basicmodelData.PreferentialMoney}}</p><strong></strong>
					</el-col>
				</el-row>
			</div>

			<div class="marketAnlestics" style="margin-bottom: 10px;">
				<p>销售连带</p>
				<el-row class="line">
					<el-col :span="8">
						<p class="title-name">
							营销支付件数
							<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.effectProductCount"
							 placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</p>
						<p class="title-money">{{basicmodelData.ProductCount}}</p>
					</el-col>
					<el-col :span="8">
						<p class="title-name">
							营销支付订单数
							<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.effectOrderCount"
							 placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</p>
						<p class="title-money">{{basicmodelData.OrderCount}}</p>
					</el-col>
					<el-col :span="8">
						<p class="title-name">
							连带率
							<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.effectAssociatedPurchaseRate"
							 placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</p>
						<p class="title-money">{{basicmodelData.AssociatedPurchaseRate + '%'}}</p><strong></strong>
					</el-col>
				</el-row>
			</div>

			<div class="marketAnlestics" style="margin-bottom: 10px;">
				<p>客单/笔单价</p>
				<el-row class="line">
					<el-col :span="8">
						<p class="title-name">
							营销支付人数
							<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.effectAllMemberCount"
							 placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</p>
						<p class="title-money">{{basicmodelData.AllMemberCount}}</p>
					</el-col>
					<el-col :span="8">
						<p class="title-name">
							客单价
							<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.effectUnitCustomerPrice"
							 placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</p>
						<p class="title-money">{{basicmodelData.UnitCustomerPrice}}</p>
					</el-col>
					<el-col :span="8">
						<p class="title-name">
							笔单价
							<el-tooltip class="item-tooltip" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.effectUnitOrderPrice"
							 placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</p>
						<p class="title-money">{{basicmodelData.UnitOrderPrice}}</p><strong></strong>
					</el-col>
				</el-row>
			</div>

			<div class="marketAnlestics">
				<div class="pin-box">
					<echart-pie :echartPieData='echartPieData'></echart-pie>
				</div>

			</div>
		</div>

		<!-- 数据趋势 -->
		<div class="survey-box">
			<p class="titles">数据趋势</p>
			<div class="marketAnlestics" style="margin-bottom: 10px;">
				<div class="marketBottom">
					<div class="check-label">投入产出：</div>
					<div class="item-box">
						<div style='display: inline-block;' v-for="item in checksList1" :key='item.index'>
							<div class="item" v-if="item.show">
								<el-checkbox style='margin-right:20px' @change='handleStructureCheck' :label="item.label" v-model="checks"
								 :disabled="checks.length>4&&!checks.includes(item.label)">{{item.name}}</el-checkbox>
							</div>
						</div>
					</div>
				</div>

				<div class="marketBottom">
					<div class="check-label">拉新复购：</div>
					<div class="item-box">
						<div style='display:inline-block;' v-for="item in checksList2" :key='item.index'>
							<div class="item" v-if="item.show">
								<el-checkbox style='margin-right:20px' @change='handleStructureCheck' :label="item.label" v-model="checks"
								 :disabled="checks.length>4&&!checks.includes(item.label)">{{item.name}}</el-checkbox>
							</div>
						</div>
					</div>
				</div>

				<div class="marketBottom">
					<div class="check-label">销售连带：</div>
					<div class="item-box">
						<div style='display:inline-block;' v-for="item in checksList3" :key='item.index'>
							<div class="item" v-if="item.show">
								<el-checkbox style='margin-right:20px' @change='handleStructureCheck' :label="item.label" v-model="checks"
								 :disabled="checks.length>4&&!checks.includes(item.label)">{{item.name}}</el-checkbox>
							</div>
						</div>
					</div>
				</div>

				<div class="marketBottom">
					<div class="check-label">流量转化：</div>
					<div class="item-box">
						<div style='display:inline-block;' v-for="item in checksList4" :key='item.index'>
							<div class="item" v-if="item.show">
								<el-checkbox style='margin-right:20px' @change='handleStructureCheck' :label="item.label" v-model="checks"
								 :disabled="checks.length>4&&!checks.includes(item.label)">{{item.name}}</el-checkbox>
							</div>
						</div>
					</div>
				</div>

				<p class="five-more">最多只能同时选择5个指标</p>

				<div class="line-box">
					<echart-line :echartLineData='echartLineData' :lineXAxisData='lineXAxisData'></echart-line>
				</div>

			</div>
		</div>

		<div class="survey-box">
			<p class="titles">营销数据概览</p>
			<!-- :render-header="renderHeader" -->
			<div class="table">
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="ActivityName" label="营销名称" :key="1"></el-table-column>
					<el-table-column prop="Roi" sortable label="ROI" :key="2" v-if="checks.includes(1)" sortable></el-table-column>
					<el-table-column prop="ActivityPayMoney" sortable label="营销支付金额(元)" :key="3" v-if="checks.includes(2)" sortable></el-table-column>
					<el-table-column prop="PreferentialMoney" sortable label="营销优惠金额(元)" :key="4" v-if="checks.includes(3)" sortable></el-table-column>
					<el-table-column prop="AllMemberCount" sortable label="营销支付人数" :key="5" v-if="checks.includes(4)" sortable></el-table-column>
					<el-table-column prop="NewMemberCount" sortable label="新成交客户" :key="6" v-if="checks.includes(5)" sortable></el-table-column>
					<el-table-column prop="OldMemberCount" sortable label="老成交客户" :key="7" v-if="checks.includes(6)" sortable></el-table-column>
					<el-table-column prop="ProductCount" sortable label="营销支付件数" :key="8" v-if="checks.includes(7)" sortable></el-table-column>
					<el-table-column prop="OrderCount" sortable label="营销支付订单数" :key="9" v-if="checks.includes(8)" sortable></el-table-column>
				</el-table>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		ActivityoverView
	} from '@/api/TurnTomySelf'
	import echartBar from '../components/echartbar'
	import echartPie from '../components/echartPie'
	import echartLine from '../components/echartLine'
	export default {
		components: {
			echartBar,
			echartPie,
			echartLine
		},
		data() {
			return {
				tooltip: {
					splicerTime: '最多可筛选14天的数据',
					ActivityPayMoney: '统计筛选时间内，支付成功的订单中，有使用营销的订单，订单金额总和',
					ActivityPayMoneyRate: '统计筛选时间内，有使用营销的订单支付金额之和/商城所有订单支付成功金额之和',
					Roi: '统计筛选时间内，营销总体投入产出比（=营销支付金额/营销优惠金额）',
					effectRoi: '统计筛选时间内，营销总体投入产出比（=营销支付金额/营销优惠金额）',
					effectPreferentialMoney: '统计筛选时间内，支付成功的订单，订单中通过营销产生的优惠金额总和',
					effectActivityPayMoney: '统计筛选时间内，支付成功的订单中，有使用营销的订单，订单金额总和',
					effectProductCount: '统计筛选时间内，支付成功并且参与营销活动的订单中，商品购买件数总和',
					effectOrderCount: '统计筛选时间内，支付成功并且参与营销的订单数量',
					effectAssociatedPurchaseRate: '统计筛选时间内，营销支付件数/营销支付订单数',
					effectAllMemberCount: '统计筛选时间内，成功支付订单的人数，且订单参与营销',
					effectUnitCustomerPrice: '统计筛选时间内，营销支付金额/营销支付人数',
					effectUnitOrderPrice: '统计筛选时间内，营销支付金额/营销支付订单数'
				},
				dateList: [],
				valueList: [],
				allCheckList: [],
				checks: [1, 4, 7, 10], //初始化
				checksList1: [{
						label: 1,
						name: 'ROI',
						show: true,
					},
					{
						label: 2,
						name: '营销支付金额',
						show: true,
					},
					{
						label: 3,
						name: '营销优惠金额',
						show: true
					},
				],
				checksList2: [{
						label: 4,
						name: '营销支付人数',
						show: true,
					},
					{
						label: 5,
						name: '新成交客户',
						show: true,
					},
					{
						label: 6,
						name: '老成交客户',
						show: true
					},
				],
				checksList3: [{
						label: 7,
						name: '营销支付件数',
						show: true,
					},
					{
						label: 8,
						name: '营销支付订单数',
						show: true,
					},
					{
						label: 9,
						name: '连带率',
						show: true
					},
				],
				checksList4: [{
						label: 10,
						name: '浏览量',
						show: true,
					},
					{
						label: 11,
						name: '访客数',
						show: true,
					},
					{
						label: 12,
						name: '转化率',
						show: true
					},
				],
				DataStatisActivityToDay: [],
				basicmodelData: {},
				totalWidth: null,
				EffectiveActivityDiscontCountWidth: 0,
				EffectiveActivityMarkdownGiftCountWidth: 0,
				EffectiveCouponCountWidth: 0,
				Loading: false,
				tableData: [],
				Datevalue: [],
				startDate: '',
				endDate: '',
				startTime: '',
				endTime: '',
				// pickerOptionsDate: {
				// 	disabledDate(time) {
				// 		return time.getTime() > Date.now() - 24 * 60 * 60 * 1000 ||
				// 			time.getTime() < Date.now() - 24 * 60 * 60 * 1000 * 15
				// 	}
				// },
				vipFansLegend: ['营销支付金额', '非营销支付金额'],
				vipFansBarData: [],
				echartPieData: [],
				roiChecks: false,
				moneyChecks: false,
				echartLineData: [],
				lineXAxisData: [],
			}
		},
		computed: {
			//开始日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endDate;
				return {
					disabledDate(time) {
						if (limitTime) {
							let minLimitTime = +new Date(limitTime) - 24 * 60 * 60 * 1000 * 14
							return (time > new Date(limitTime) || time < new Date(minLimitTime))
						}
					}
				};
			},
			//结束日期选择器限制选择
			minOptions: function() {
				let limitTime = this.startDate;
				return {
					disabledDate(time) {
						if (limitTime) {
							let maxLimitTime = +new Date(limitTime) + 24 * 60 * 60 * 1000 * 14
							return (time < new Date(limitTime) || time > new Date(maxLimitTime))
						}
					}
				};
			},
		},
		created() {
			//自定义
			let time = new Date()
			let startTime = new Date(time - 1000 * 60 * 60 * 24 * 14)
			let lastTime = new Date(time - 1000 * 60 * 60 * 24)
			// this.Datevalue = []
			// this.Datevalue.push(startTime)
			// this.Datevalue.push(lastTime)
			this.startDate = startTime
			this.endDate = lastTime
			this.getCustomizeTime()

		},
		methods: {
			
			changeRangeTime() {
				if (this.startDate != '' && this.endDate != '') {
					this.getInitoverView()
				}
			},
			handleStructureCheck() {
				let checks = this.checks.sort((x, y) => {
					return x - y
				})
				this.getdataChecklist()

			},
			formatTen(num) {
				return num > 9 ? (num + "") : ("0" + num);
			},
			//格式化时间 YYYY-MM-DD/ YYYY-MM-DD hh:mm:ss
			formatDate(date) {
				date = new Date(date)
				let year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();
				return year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
			},
			getCustomizeTime() {
				this.startTime = this.formatDate(this.startDate)
				this.endTime = this.formatDate(this.endDate)
				this.getInitoverView()
			},
			getdataChecklist() {
				this.allCheckList = this.checksList1.concat(this.checksList2).concat(this.checksList3).concat(this.checksList4)
				//所有的checklist
				this.lineXAxisData = this.dateList
				let checkedList = []
				this.allCheckList.map(item => {
					this.checks.map(items => {
						if (items == item.label) {
							checkedList.push(item)
						}
					})
				})
				checkedList.map(item => {
					item.value = this.valueList[item.label - 1]
				})

				this.echartLineData = checkedList
			},
			async getInitoverView() {
				this.Loading = true
				try {
					let data = {
						StartTime: this.startTime,
						EndTime: this.endTime
					}
					let result = await ActivityoverView(data)
					if (result.IsSuccess) {
						this.basicmodelData = result.Result

						this.totalWidth = Number(result.Result.EffectiveActivityDiscontCount + result.Result.EffectiveActivityMarkdownGiftCount +
							result.Result.EffectiveCouponCount)
						//
						this.EffectiveActivityDiscontCountWidth = (result.Result.EffectiveActivityDiscontCount / this.totalWidth).toFixed(
							2) * 100
						this.EffectiveActivityMarkdownGiftCountWidth = (result.Result.EffectiveActivityMarkdownGiftCount / this.totalWidth)
							.toFixed(2) * 100
						this.EffectiveCouponCountWidth = (result.Result.EffectiveCouponCount / this.totalWidth).toFixed(2) * 100

						//支付金额
						this.DataStatisActivityToDay = result.Result.DataStatisActivityToDay
						this.dateList = []
						let ActivityPayMoneyList = []
						let NoActivityPayMoneyList = []
						let RoiList = []
						let PreferentialMoneyList = []
						let AllMemberCountList = []
						let NewMemberCountList = []
						let OldMemberCountList = []
						let ProductCountList = []
						let OrderCountList = []
						let AssociatedPurchaseRateList = []
						let PVList = []
						let UVList = []
						let ConversionRateList = []
						this.DataStatisActivityToDay.map(item => {
							let date1 = item.Date.split(" ")[0]
							this.dateList.push(date1)
							ActivityPayMoneyList.push(item.ActivityPayMoney)
							NoActivityPayMoneyList.push(item.NoActivityPayMoney)
							RoiList.push(item.Roi)
							PreferentialMoneyList.push(item.PreferentialMoney)
							AllMemberCountList.push(item.AllMemberCount)
							NewMemberCountList.push(item.NewMemberCount)
							OldMemberCountList.push(item.OldMemberCount)
							ProductCountList.push(item.ProductCount)
							OrderCountList.push(item.OrderCount)
							AssociatedPurchaseRateList.push(item.AssociatedPurchaseRate)
							PVList.push(item.PV)
							UVList.push(item.UV)
							ConversionRateList.push(item.ConversionRate)
						})

						this.valueList = [RoiList, ActivityPayMoneyList, PreferentialMoneyList, AllMemberCountList, NewMemberCountList,
							OldMemberCountList, ProductCountList, OrderCountList, AssociatedPurchaseRateList, PVList, UVList,
							ConversionRateList
						]
						this.vipFansBarData = [{
							dateList: this.dateList,
							ActivityPayMoneyList: ActivityPayMoneyList,
							NoActivityPayMoneyList: NoActivityPayMoneyList
						}]

						let oldmemCoutpercent = ((this.basicmodelData.OldMemberCount / this.basicmodelData.AllMemberCount) * 100).toFixed(
							2)
						let newmemCoutpercent = ((this.basicmodelData.NewMemberCount / this.basicmodelData.AllMemberCount) * 100).toFixed(
							2)
						//圆饼图
						this.echartPieData = [{
							name: '老成交客户占比' + oldmemCoutpercent + '%',
							value: this.basicmodelData.OldMemberCount
						}, {
							name: '新成交客户占比' + newmemCoutpercent + '%',
							value: this.basicmodelData.NewMemberCount
						}]
						this.tableData = result.Result.DataStatisActivityToActivity

						this.getdataChecklist()

						// 						this.echartLineData.map(item =>{
						// 							
						// 						})

						// 						{
						// 								name: 'ROI',
						// 								value: [100, 200, 300, 200, 300, 500, 100, 100]
						// 							},
						// 							{
						// 								name: '营销支付人数',
						// 								value: [300, 500, 100, 100, 100, 200, 300, 200, ]
						// 							},
						// 							{
						// 								name: '营销支付件数',
						// 								value: [200, -100, 300, -100, 500, -200, 300, 200]
						// 							},
						// 							{
						// 								name: '浏览量',
						// 								value: [100, -100, 200, -100, 200, -200, 500, 200]
						// 							},
						//表格

					}
				} catch (err) {

				} finally {
					this.Loading = false
				}
			},
			//改变日期
			ChangeDatevalue() {
				this.getCustomizeTime()
			},
			renderHeader(h, {
				column
			}) {
				return h(
					'span',
					[
						h('span', column.label, ),
						h('span', {
							class: 'el-icon-warning icon',
							style: 'margin:0 5px',

						}),
					],
				)
			},
		}
	}
</script>

<style lang="less" scoped>
	.marketingOverview {
		padding: 15px;
		font-size: 14px;

		.splicer-time {
			width: 100%;
			background: #fff;
			padding: 15px;
			margin-bottom: 10px;

			.small-show {
				font-size: 12px;
				color: #999;
				margin-left: 70px;
			}
		}

		.survey-box {
			padding: 15px;
			background: #fff;
			margin-bottom: 10px;

			.titles {
				padding-bottom: 10px;
			}

			.marketAnlestics {
				padding: 15px;
				background: #F5F5F5;
			}
		}

		.show-middles {
			width: 350px;
			height: 40px;
			display: flex;
			flex-wrap: nowrap;

			.green-box {
				display: inline-block;
				// width: 20%;
				height: 40px;
				background: #4ecb73;
			}

			.yellow-box {
				display: inline-block;
				// width: 50%;
				height: 40px;
				background: #fbd437;
			}

			.red-box {
				display: inline-block;
				// width: 30%;
				height: 40px;
				background: #f2637b;
			}
		}

		.color-show {
			margin-top: 10px;

			.limite-time {
				font-size: 13px;

				span {
					display: inline-block;
					width: 10px;
					height: 10px;
					border-radius: 100%;
					color: #999;
					margin: 0 5px 0 15px;
				}

				.green-circle {
					background: #4ecb73;
				}

				.yellow-circle {
					background: #fbd437;
				}

				.red-circle {
					background: #f2637b;
				}
			}
		}

		.title-name {
			color: #757171;
			font-size: 13px;
		}

		.title-money {
			color: #333;
			font-size: 14px;
			font-weight: bold;
		}

		.echart-box {
			padding: 10px;
			width: 100%;
			height: 300px;
			margin-top: 10px;
		}

		.icon {
			font-size: 14px;
			color: #999;
			margin-left: 5px;
			cursor: pointer;
		}

		.pin-box {
			width: 100%;
			height: 300px;
		}

		.marketBottom {
			margin-bottom: 10px;
			display: flex;
		}

		.five-more {
			font-size: 12px;
			color: #999;
			margin-left: 60px;
		}

		.line-box {
			height: 300px;
			width: 100%;
			background: #fff;
			padding: 20px 15px;
			margin-top: 20px;
		}

	}
</style>

<template>
  <div ref="echartPie" style="width:100%;height:100%;">
  </div>
</template>
<script>
import echarts from 'echarts'
export default {
  name:'echartPie',
  props: {
    echartPieData:{
      type:Array,
      default:[]
    },
    
  },
  data(){
    return {
      colors : ['#5793f3', '#d14a61'],
      legends:['成交客户构成']
    }
  },
  mounted(){
    this.getEchart();
  },
  watch:{
    echartPieData(n,o){
      this.$nextTick(()=> {
        this.getEchart();
      });
    },
  },
  methods:{
    getEchart(){
        let legend=[]
        this.echartPieData.forEach(t => {
          legend.push(t.name)
        });
				// ({d}%)
        let myChart =  echarts.init(this.$refs.echartPie)
        let option = {
          tooltip: {
              trigger: 'item',
              formatter: "{a} <br/>{b} ({c}人)"
          },
          title: {
              text: '拉新复购',
              textStyle :{
                color:'#666',
                fontSize:'14'
              }
          },
          legend: {
              orient: 'vertical',
              left:10,
              top:60,
              show: true,
              data:legend
          },
          color:['#5bc49f','#ff7c7c'],
          series: [
            {
              name:'成交客户构成',
              type:'pie',
              center: ['50%', '50%'],
              radius: ['50%', '90%'],
              avoidLabelOverlap: false,
              label: {
                  normal: {
                      show: false,
                      position: 'center'
                  },
                  emphasis: {
                      show: true,
                      textStyle: {
                          fontSize: '14',
                          fontWeight: 'bold'
                      }
                  }
              },
              labelLine: {
                  normal: {
                      show: false
                  }
              },
              data:this.echartPieData
            }
          ]
        };
        myChart.setOption(option);
    }
  }
}
</script>
<template>
	<div ref="echartBar" style="width:100%;height:100%;">
	</div>
</template>
<script>
	import echarts from 'echarts'
	export default {
		name: 'echartBar',
		props: {
			echartBarData: {
				type: Array,
				default: []
			},
			legendData: {
				type: Array,
				default: []
			},
		},
		data() {
			return {
				dateList: []
			}
		},
		mounted() {
			window.addEventListener('resize', this.resize)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resize)
		},
		watch: {
			echartBarData(n, o) {
				this.$nextTick(() => {
					this.getEchart();
				});
			},
		},
		methods: {
			resize(){
				this.myChart.resize()
			},
			getEchart() {
				this.myChart = echarts.init(this.$refs.echartBar)
				this.dateList = this.echartBarData[0].dateList

				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						}
					},
					legend: {
						data: this.legendData
					},
					grid: {
						left: '1%',
						top: '15%',
						right: '3%',
						bottom: '4%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: this.dateList
					},
					yAxis: {
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed'
							}
						},
						type: 'value',
					},
					series: [{
							name: this.legendData[0],
							type: 'bar',

							itemStyle: {
								normal: {
									color: '#60acfc',
								}
							},
							label: {
								normal: {
									show: true,
									position: 'top'
								}
							},
							z: 10,
							data: this.echartBarData[0].ActivityPayMoneyList
						},
						{
							name: this.legendData[1],
							type: 'bar',
							itemStyle: {
								normal: {
									color: '#feb64d',
								}
							},
							label: {
								normal: {
									show: true,
									position: 'top'
								}
							},
							z: 5,
							data: this.echartBarData[0].NoActivityPayMoneyList
						},
					]
				};
				this.myChart.setOption(option, true);
			}
		}
	}
</script>
